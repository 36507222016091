import LogoColpani from '../Assets/LogoColpani';
import FacebookIcon from '../Icons/FacebookIcon';
import InstagramIcon from '../Icons/InstagramIcon';
import LinkedinIcon from '../Icons/LinkedinIcon';
import LocationPinIcon from '../Icons/LocationPinIcon';
import WhatsappIcon from '../Icons/WhatsappIcon';
import * as S from './styles';

interface IFooter { }

const socialMedia = [
  {
    id: 'instagram',
    icon: <InstagramIcon color="#35d32f" />,
    link: 'https://www.instagram.com/colpaniengenharia/'
  },
  {
    id: 'facebook',
    icon: <FacebookIcon color="#35d32f" />,
    link: 'https://www.facebook.com/profile.php?id=100063645276456'
  },
  {
    id: 'linkedin',
    icon: <LinkedinIcon color="#35d32f" />,
    link: 'https://www.linkedin.com/company/colpaniengenharia'
  },
];

const Footer: React.FC<IFooter> = () => {
  return (
    <S.Footer>
      <S.Container>
        <S.MainContent>
          <S.SocialMedia>
            <S.Description>
              Siga a gente nas <span>nossas redes sociais</span>:
            </S.Description>

            <S.SocialMediaList>
              {socialMedia.map((item) => (
                <S.SocialMediaItem
                  key={item.id}
                  target='_blank'
                  href={item.link}
                >
                  {item.icon}
                </S.SocialMediaItem>
              ))}
            </S.SocialMediaList>
          </S.SocialMedia>

          <S.Address>
            <div>
              <LocationPinIcon color="#38d430" />

              <S.Description>
                <b>Onde estamos</b>
              </S.Description>
            </div>

            <S.AddressContent>
              Joinville (Matriz) - Rua Dona Elza Meinert, 1.142, sala 301 Bairro Costa e Silva - CEP 89218-650
            </S.AddressContent>

            <S.AddressContent>
              Itapema (Filial) - Rua 312, 61, sala 03 Meia Praia - CEP 88220-000
            </S.AddressContent>
          </S.Address>

          <S.GetInTouch>
            <div>
              <WhatsappIcon color="#35d32f" />

              <S.PhoneLink
                target='_blank'
                href='https://wa.me/5547999979055?text=Olá, Colpani!'
              >
                <span>47</span> 9 9997-9055
              </S.PhoneLink>
            </div>

            <S.Description>
              Entre em contato com a gente.
            </S.Description>
          </S.GetInTouch>
        </S.MainContent>

        <S.Copyright>
          <LogoColpani color='white' />

          <S.CopywrigthText>
            <span>©</span> 2023 <span>-</span> Todos os direitos reservados
          </S.CopywrigthText>
        </S.Copyright>
      </S.Container>
    </S.Footer>
  );
};

export default Footer;
