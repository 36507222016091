import * as S from './styles';

interface ISolutions { }

const solutions = [
  {
    id: 1,
    title: 'Engenharia de Infraestrutura Rodoviária',
    description: 'Estudo de tráfego, projeto de drenagem, projeto de terraplanagem, projeto de pavimentação, projeto as built, entre outros.'
  },
  {
    id: 2,
    title: 'Gerenciamento, Supervisão e Fiscalização de Obra',
    description: 'Acompanhamento in loco durante toda a execução da obra pela equipe técnica. Fiscalização, locação e medição de obra.'
  },
  {
    id: 3,
    title: 'Arquitetura e Urbanismo',
    description: 'Projeto de loteamento e condomínio, projeto arquitetônio e hidráulico.'
  },
  {
    id: 4,
    title: 'Topografia e Geodésia',
    description: 'Levantamento topográfio. Georreferenciamento, desmembramento, unificação, retificação e usucapião de áreas.'
  },
];

const Solutions: React.FC<ISolutions> = () => {
  return (
    <S.Solutions id="services">
      <S.Content>
        <S.SectionTitle>Nossas soluções</S.SectionTitle>

        <S.SectionDescription>
          Há <span>10 anos</span> somos movidos para oferecer os melhores
          serviços para <span>projetos inteligentes</span>:
        </S.SectionDescription>

        <S.Items>
          {
            solutions.map((item) => (
              <S.Item key={item.id}>
                <S.Title>{item.title}</S.Title>

                <S.Description>{item.description}</S.Description>
              </S.Item>
            ))
          }
        </S.Items>
      </S.Content>
    </S.Solutions>
  );
};

export default Solutions;
