import * as S from './styles';
import FacebookIcon from '../Icons/FacebookIcon';
import LinkedinIcon from '../Icons/LinkedinIcon';
import WhatsappIcon from '../Icons/WhatsappIcon';
import LogoColpani from '../Assets/LogoColpani';
import InstagramIcon from '../Icons/InstagramIcon';

interface IHeader { }

const socialMedia = [
  {
    id: 'instagram',
    icon: <InstagramIcon color="#fff" />,
    link: 'https://www.instagram.com/colpaniengenharia/'
  },
  {
    id: 'facebook',
    icon: <FacebookIcon color="#fff" />,
    link: 'https://www.facebook.com/profile.php?id=100063645276456'
  },
  {
    id: 'linkedin',
    icon: <LinkedinIcon color="#fff" />,
    link: 'https://www.linkedin.com/company/colpaniengenharia'
  },
  {
    id: 'whatsapp',
    icon: <WhatsappIcon color="#fff" />,
    link: 'https://wa.me/5547999979055?text=Olá, Colpani!'
  },
];

const links = [
  {
    to: 'services',
    id: 'servicos',
    name: 'Serviços',
  },
  {
    to: 'clients',
    id: 'clientes',
    name: 'Clientes',
  },
  {
    to: 'projects',
    id: 'projetos',
    name: 'Projetos',
  },
  {
    to: 'about',
    id: 'sobre',
    name: 'Sobre nós',
  }
]

const Header: React.FC<IHeader> = () => {
  const onNavigate = (to: string) => {
    const element = document.getElementById(to);

    if (element) {
      const top = element.offsetTop;
      window.scrollTo({ top: top - 100, behavior: 'smooth' });
    }
  }

  return (
    <S.Header>
      <S.HeaderContainer>
        <S.Logo>
          <LogoColpani />
        </S.Logo>

        <S.Navigation>
          <S.Nav>
            {
              links.map(link => (
                <S.NavItem
                  key={link.id}
                  onClick={() => onNavigate(link.to)}
                >
                  {link.name}
                </S.NavItem>
              ))
            }
          </S.Nav>

          <S.Social>
            {
              socialMedia.map(social => (
                <S.SocialItem key={social.id} href={social.link} target="_blank">
                  {social.icon}
                </S.SocialItem>
              ))
            }
          </S.Social>
        </S.Navigation>
      </S.HeaderContainer>
    </S.Header>
  );
};

export default Header;
