interface IIcon {
  color?: string;
}

const LocationPinIcon: React.FC<IIcon> = ({ color }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M14 2.33334C9.48498 2.33334 5.83331 5.98501 5.83331 10.5C5.83331 16.625 14 25.6667 14 25.6667C14 25.6667 22.1666 16.625 22.1666 10.5C22.1666 5.98501 18.515 2.33334 14 2.33334ZM8.16665 10.5C8.16665 7.28001 10.78 4.66668 14 4.66668C17.22 4.66668 19.8333 7.28001 19.8333 10.5C19.8333 13.86 16.4733 18.8883 14 22.0267C11.5733 18.9117 8.16665 13.825 8.16665 10.5Z" fill={color || '#fff'} />
        <path d="M14 13.4167C15.6108 13.4167 16.9166 12.1108 16.9166 10.5C16.9166 8.88918 15.6108 7.58334 14 7.58334C12.3891 7.58334 11.0833 8.88918 11.0833 10.5C11.0833 12.1108 12.3891 13.4167 14 13.4167Z" fill={color || '#fff'} />
      </g>
    </svg>
  )
};

export default LocationPinIcon;