import styled from "styled-components";
import Container from "../Container";

export const VideoSection = styled.section`
  overflow-x: hidden;
  padding: 60px 20px;
  background: linear-gradient(-180deg, #35d32f 50%, #3c4543 50% 100%);
  background: -webkit-linear-gradient(-90deg, #35d32f 50%, #3c4543 50% 100%);
`;

export const Content = styled(Container)`
  gap: 72px;
  display: flex;
  max-width: 960px;
  align-items: center;
  flex-direction: column;
`;

export const Text = styled.p`
  width: 100%;
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  max-width: 520px;
  text-align: center;

  span {
    color: #3c4543;
    font-weight: 800;
  }

  @media (max-width: 580px) {
    font-size: 22px;
  }
`;

export const Video = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 40px;
  padding-bottom: 56.25%;
  box-shadow: 20px 0 40px 10px rgba(0, 0, 0, 0.6);

  iframe {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  @media (max-width: 800px) {
    border-radius: 20px;
  }
`;

export const Highlights = styled.ul`
  width: 100%;
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

export const Highlight = styled.li`
  gap: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 550px) {
    width: 100%;
    padding: 40px 0;
    max-width: 240px;
    border-bottom: 1px solid #ffffff40;

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
`;

export const BigNumber = styled.p`
  color: #35d32f;
  font-size: 72px;
  font-weight: 800;
  line-height: 100%;

  span {
    background: linear-gradient(90deg, #35d32f, #3c4543);
    background: -webkit-linear-gradient(0deg, #35d32f, #3c4543);

    background-clip: text;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (min-width: 550px) and (max-width: 720px) {
    font-size: 48px;
  }
`;

export const Label = styled.p`
  color: #fff;
  font-size: 20px;
  font-weight: 600;

  @media (max-width: 720px) {
    font-size: 16px;
  }
`;
