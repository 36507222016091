import * as S from './styles';

interface IVideoSection { }

const VideoSection: React.FC<IVideoSection> = () => {
  return (
    <S.VideoSection id="about">
      <S.Content>
        <S.Text>
          Com <span>83% do mercado conquistado, </span>
          a Colpani é referência nacional em
          <span> regularização de acessos </span>
          e projetos de infraestrutura rodoviária<span>.</span>
        </S.Text>

        <S.Video>
          <iframe
            frameBorder={0}
            title="Vídeo institucional da Colpani"
            src="https://www.youtube.com/embed/B5r42QlZ3pg?vq=hd1080&modestbranding=1&rel=0&iv_load_policy=3&color=white&disablekb=1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          />
        </S.Video>

        <S.Highlights>
          <S.Highlight>
            <S.BigNumber><span>+</span>210</S.BigNumber>

            <S.Label>projetos aprovados</S.Label>
          </S.Highlight>

          <S.Highlight>
            <S.BigNumber>
              <span>+</span>50
            </S.BigNumber>

            <S.Label>as-built aprovados</S.Label>
          </S.Highlight>

          <S.Highlight>
            <S.BigNumber>
              <span>+</span>38
            </S.BigNumber>

            <S.Label>cidades brasileiras</S.Label>
          </S.Highlight>
        </S.Highlights>
      </S.Content>
    </S.VideoSection>
  );
};

export default VideoSection;
