import styled from "styled-components";
import Container from "../Container";

export const Header = styled.header`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 20px;
  position: fixed;
  background-color: #fff;
`;

export const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 440px) {
    justify-content: center;
  }
`;

export const Logo = styled.div`
  width: 220px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 600px) {
    width: 180px;
    height: 58px;
  }
`;

export const Navigation = styled.div`
  gap: 40px;
  display: flex;
  align-items: center;

  @media (max-width: 440px) {
    display: none;
  }
`;

export const Nav = styled.ul`
  display: flex;
  list-style: none;
  align-items: center;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  padding: 0 24px;
  cursor: pointer;
  user-select: none;
  border-right: 1px solid #35d32f;

  &:last-of-type {
    padding: 0;
    padding-left: 24px;
    border-right: none;
  }
`;

export const Social = styled.div`
  gap: 6px;
  display: flex;
  list-style: none;
  align-items: center;
`;

export const SocialItem = styled.a`
  width: 24px;
  height: 24px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #35d32f;

  svg {
    width: 14px;
    height: 14px;
  }

  &:nth-of-type(3) {
    svg {
      width: 13px;
      height: 13px;
    }
  }

  &:nth-of-type(4) {
    svg {
      width: 15px;
      height: 15px;
    }
  }
`;
