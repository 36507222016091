import styled from "styled-components";
import Container from "../Container";

export const Solutions = styled.section`
  color: #3c4543;
`;

export const Content = styled(Container)`
  gap: 20px;
  display: flex;
  padding: 60px 20px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const SectionTitle = styled.h3`
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const SectionDescription = styled.p`
  width: 100%;
  font-size: 26px;
  font-weight: 500;
  max-width: 600px;
  text-align: center;

  span {
    color: #35d32f;
    font-weight: 800;
    font-style: italic;
  }

  @media (max-width: 640px) {
    font-size: 22px;
  }
`;

export const Items = styled.ul`
  gap: 20px;
  display: grid;
  margin-top: 40px;
  list-style: none;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 640px) {
    width: 100%;
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.li`
  gap: 12px;
  display: flex;
  padding: 24px;
  max-width: 320px;
  min-height: 300px;
  text-align: center;
  align-items: center;
  border-radius: 24px;
  flex-direction: column;
  justify-content: center;
  background-color: #35d32f;

  @media (max-width: 640px) {
    min-height: 0;
    max-width: 100%;
    grid-template-columns: 1fr;
  }
`;

export const Title = styled.h4`
  color: #fff;
  font-size: 24px;
  font-weight: 800;

  @media (max-width: 640px) {
    font-size: 20px;
  }
`;

export const Description = styled.p`
  font-size: 18px;
  font-weight: 600;

  @media (max-width: 640px) {
    font-size: 16px;
  }
`;
