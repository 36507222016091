import Hero from './components/Hero';
import Header from './components/Header';
import Footer from './components/Footer';
import Clients from './components/Clients';
import Projects from './components/Projects';
import Solutions from './components/Solutions';
import GetInTouch from './components/GetInTouch';
import VideoSection from './components/VideoSection';

function App() {
  return (
    <div>
      <Header />
      <Hero />
      <VideoSection />
      <Solutions />
      <Projects />
      <Clients />
      <GetInTouch />
      <Footer />
    </div>
  );
}

export default App;
