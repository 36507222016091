import C from "../Container";
import styled, { css } from "styled-components";

export const Clients = styled.section`
  padding: 60px 20px;
  position: relative;
`;

export const Container = styled(C)`
  gap: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TitleDescription = styled.div`
  gap: 12px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h3`
  color: #3c4543;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const Description = styled.p`
  width: 100%;
  color: #3c4543;
  font-size: 24px;
  max-width: 290px;

  span {
    color: #35d32f;
    font-weight: 800;
  }
`;

export const Slider = styled.div`
  width: 100%;
  height: 140px;
  padding: 0 90px;
  max-width: 860px;
  position: relative;

  @media (max-width: 550px) {
    padding: 0 48px;
  }
`;

export const SliderContainer = styled.div<{ offset: number }>`
  ${({ offset }) => css`
    gap: 5%;
    width: 100%;
    display: flex;
    height: 100%;
    overflow: hidden;
    position: relative;
    align-items: center;

    @media (max-width: 550px) {
      gap: 10%;
    }

    > :first-child {
      margin-left: ${offset * -35}%;
      transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);

      @media (max-width: 550px) {
        margin-left: ${offset * -55}%;
      }
    }
  `}
`;

export const Slide = styled.div<{ img: string }>`
  ${({ img }) => css`
    width: 30%;
    height: 80%;
    flex-shrink: 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${img});

    @media (max-width: 550px) {
      width: 45%;
    }
  `}
`;

export const Controls = styled.div`
  left: 0;
  top: 50%;
  width: 100%;
  display: flex;
  padding: 0 20px;
  position: absolute;
  transform: translateY(-50%);
  justify-content: space-between;

  @media (max-width: 550px) {
    padding: 0;
  }
`;

export const Left = styled.button<{ isLast: boolean }>`
  ${({ isLast }) => css`
    width: 36px;
    height: 36px;
    border: none;
    opacity: 0.8;
    cursor: pointer;
    background: none;
    position: relative;
    border-radius: 32px;
    border: 2px solid #35d32f;

    span {
      left: 9px;
      content: "";
      width: 12px;
      height: 2px;
      position: absolute;
      background-color: #35d32f;
      transition: all 0.2s ease-in-out;

      &:first-of-type {
        top: 11px;
        transform: rotateZ(${isLast ? "-90deg" : "-45deg"});
      }

      &:last-of-type {
        top: 19px;
        transform: rotateZ(${isLast ? "90deg" : "45deg"});
      }
    }
  `}
`;

export const Right = styled(Left)`
  ${({ isLast }) => css`
    span {
      left: 10px;

      &:first-of-type {
        transform: rotateZ(${isLast ? "90deg" : "45deg"});
      }

      &:last-of-type {
        transform: rotateZ(${isLast ? "-90deg" : "-45deg"});
      }
    }
  `}
`;
