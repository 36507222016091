import * as S from './styles';

const projects = [
  {
    id: 1,
    img: './project-juninho.jpg',
    title: 'Posto Juninho, Porto Belo/SC',
    description: 'Obra de implantação de acesso na marginal norte da BR-101 Km 159+000m.',
  },
  {
    id: 2,
    img: './project-delta.jpg',
    title: 'Posto Delta, Piçarras/SC',
    description: 'Obra do projeto de regularização de três acessos, com implantação de via marginal na BR-101 Km 097+000m Norte.',
  },
  {
    id: 3,
    img: './project-havan.jpg',
    title: 'Loja Havan, Palhoça/SC',
    description: 'Obra do projeto de regularização de acesso na BR-101 Km 216+000m Sul.',
  },
];

interface IProjects { }

const Projects: React.FC<IProjects> = () => {
  return (
    <S.Projects id="projects">
      <S.Container>
        <S.Content>
          <S.Title>Projetos</S.Title>

          <S.Description>
            <span>Projetos em destaque</span> que contaram
            com a assinatura da Colpani:
          </S.Description>
        </S.Content>

        <S.Items>
          {projects.map((project) => (
            <S.Item key={project.id}>
              <S.Image img={project.img} />

              <S.ItemContent>
                <S.ItemTitle>
                  {project.title}
                </S.ItemTitle>

                <S.ItemDescription>
                  {project.description}
                </S.ItemDescription>
              </S.ItemContent>
            </S.Item>
          ))}
        </S.Items>

        <S.CallToAction
          target='_blank'
          href='https://wa.me/5547999979055?text=Olá, Colpani!'
        >
          Faça um orçamento
        </S.CallToAction>
      </S.Container>
    </S.Projects>
  );
};

export default Projects;
