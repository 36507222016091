import styled, { css } from "styled-components";
import C from "../Container";

export const Projects = styled.section`
  display: flex;
  position: relative;
  padding: 60px 20px;
  align-items: center;
  background-color: #3c4543;

  @media (max-width: 940px) {
    flex-direction: column;
  }
`;

export const Container = styled(C)`
  gap: 60px;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  gap: 8px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h3`
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const Description = styled.p`
  width: 100%;
  color: #fff;
  font-size: 24px;
  max-width: 480px;

  span {
    color: #35d32f;
    font-weight: 800;
    font-style: italic;
  }
`;

export const CallToAction = styled.a`
  border: none;
  color: #3c4543;
  cursor: pointer;
  font-size: 14px;
  background: none;
  box-shadow: none;
  font-weight: 700;
  padding: 12px 32px;
  align-self: center;
  border-radius: 20px;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #35d32f;
`;

export const Items = styled.div`
  display: flex;
  color: white;
  flex-direction: column;

  @media (max-width: 720px) {
    gap: 40px;
  }
`;

export const ItemContent = styled.div`
  width: 100%;
  max-width: 320px;
  position: relative;

  &:before {
    left: -20%;
    content: "";
    width: 100%;
    height: 2px;
    bottom: -32px;
    max-width: 200px;
    position: absolute;
    background-color: #35d32f;
  }

  @media (max-width: 720px) {
    max-width: 100%;
    text-align: left !important;

    &:before {
      display: none;
    }
  }
`;

export const Item = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  margin-top: -40px;
  position: relative;
  align-items: center;

  &:first-of-type {
    margin-top: 0;
  }

  &:nth-of-type(2) {
    flex-direction: row-reverse;

    ${ItemContent} {
      text-align: right;

      &:before {
        left: auto;
        right: -20%;
      }
    }
  }

  @media (max-width: 720px) {
    margin-top: 0;
    flex-direction: column !important;
  }
`;

export const Image = styled.div<{ img: string }>`
  ${({ img }) => css`
    width: 60%;
    height: 460px;
    flex-shrink: 0;
    border-radius: 40px;
    background-size: cover;
    background-position: center;
    background-image: url(${img});
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);

    @media (max-width: 1000px) {
      height: 320px;
      border-radius: 20px;
    }

    @media (max-width: 720px) {
      width: 100%;
    }

    @media (max-width: 480px) {
      height: 240px;
    }
  `}
`;

export const ItemTitle = styled.h4``;

export const ItemDescription = styled.p``;

// export const Slider = styled.div<{ offset: number }>`
//   ${({ offset }) => css`
//     top: 0;
//     right: 0;
//     width: 50%;
//     display: flex;
//     height: 100%;
//     overflow: hidden;
//     min-width: 600px;
//     position: absolute;

//     > :first-child {
//       margin-left: ${offset * -100}%;
//       transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
//     }
//   `}

//   @media (max-width: 940px) {
//     width: 100%;
//     min-width: 0;
//     height: 400px;
//     position: relative;
//   }
// `;

// export const Slide = styled.div<{ img: string }>`
//   ${({ img }) => css`
//     width: 100%;
//     height: 100%;
//     flex-shrink: 0;
//     background-color: #eee;
//     background-size: cover;
//     background-position: center;
//     background-image: url(${img});
//   `}
// `;

// export const Controls = styled.div`
//   left: 0;
//   top: 50%;
//   width: 100%;
//   display: flex;
//   padding: 0 20px;
//   position: absolute;
//   justify-content: space-between;
// `;

// export const Left = styled.button<{ isLast: boolean }>`
//   ${({ isLast }) => css`
//     width: 20px;
//     height: 32px;
//     border: none;
//     opacity: 0.8;
//     cursor: pointer;
//     background: none;
//     position: relative;

//     span {
//       left: 0;
//       content: "";
//       width: 16px;
//       height: 3px;
//       position: absolute;
//       background-color: #fff;
//       transition: all 0.2s ease-in-out;

//       &:first-of-type {
//         top: 6px;
//         transform: rotateZ(${isLast ? '-90deg' : '-45deg'});
//       }

//       &:last-of-type {
//         top: 16px;
//         transform: rotateZ(${isLast ? '90deg' : '45deg'});
//       }
//     }
//   `}
// `;

// export const Right = styled(Left)`
//   ${({ isLast }) => css`
//     span {
//       &:first-of-type {
//         transform: rotateZ(${isLast ? '90deg' : '45deg'});
//       }

//       &:last-of-type {
//         transform: rotateZ(${isLast ? '-90deg' : '-45deg'});
//       }
//     }
//   `}
// `;

// export const Indicators = styled.div`
//   gap: 4px;
//   left: 50%;
//   display: flex;
//   bottom: 20px;
//   position: absolute;
//   align-items: center;
//   transform: translateX(-50%);
// `;

// export const Indicator = styled.span<{ selected: boolean }>`
//   ${({ selected }) => css`
//     cursor: pointer;
//     border-radius: 12px;
//     background-color: white;
//     transition: all 0.2s ease-in-out;
//     width: ${selected ? '12px' : '8px'};
//     height: ${selected ? '12px' : '8px'};
//   `}
// `;
