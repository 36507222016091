import { useState } from 'react';
import * as S from './styles';
import axios from 'axios';

interface IGetInTouch { }

const GetInTouch: React.FC<IGetInTouch> = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [service, setService] = useState('');
  const [message, setMessage] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [loading, setLoading] = useState(false);

  const clear = () => {
    setName('')
    setEmail('')
    setService('')
    setMessage('')
    setWhatsapp('')
  };

  const onSubmit = async () => {
    setLoading(true);
    setError('');

    const missingRequiredFields = [name, email, message, whatsapp]
      .filter((field) => !field);

    if (missingRequiredFields.length) {
      setError('Preencha todos os campos obrigatórios: nome, e-mail, WhatsApp e mensagem')
      setLoading(false);
      return;
    }

    const body = {
      name,
      email,
      service,
      message,
      whatsapp: applyMask(whatsapp),
    }

    try {
      await axios.post(
        'https://kxuilww62h.execute-api.us-east-1.amazonaws.com/production/v1/colpani/message',
        body,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      alert('Sucesso! Recebemos sua mensagem e retornaremos em breve')
      clear();
    } catch (error) {
      alert('Houve um erro ao enviar a sua mensagem, que tal entrar em contato pelo WhatsApp?')
    } finally {
      setLoading(false)
    }
  };

  const onChangeWhatsapp = (v: string) => {
    const onlyNumbers = v.replace(/[^0-9]/g, '');
    setWhatsapp(onlyNumbers);
  }

  const applyMask = (v: string) => {
    const onlyNumbers = v.replace(/[^0-9]/g, '');

    if (onlyNumbers.length <= 2) {
      return onlyNumbers;
    }

    if (onlyNumbers.length <= 6) {
      return `${onlyNumbers.slice(0, 2)} ${onlyNumbers.slice(2)}`;
    }

    if (onlyNumbers.length <= 10) {
      return `${onlyNumbers.slice(0, 2)} ${onlyNumbers.slice(2, 6)} ${onlyNumbers.slice(6)}`;
    }

    return `${onlyNumbers.slice(0, 2)} ${onlyNumbers.slice(2, 7)} ${onlyNumbers.slice(7, 11)}`;
  }

  return (
    <S.GetInTouch>
      <S.Container>
        <S.FormBox isLoading={loading}>
          <S.Title>Vamos conversar?</S.Title>

          <input
            type="text"
            value={name}
            placeholder="Nome"
            onChange={({ target }) => { setName(target.value); setError('') }}
          />

          <input
            type="text"
            value={service}
            placeholder="Serviço"
            onChange={({ target }) => { setService(target.value); setError('') }}
          />

          <div>
            <input
              type="text"
              value={email}
              placeholder="E-mail"
              onChange={({ target }) => { setEmail(target.value); setError('') }}
            />

            <input
              type="text"
              value={applyMask(whatsapp)}
              placeholder="WhatsApp"
              onChange={({ target }) => { onChangeWhatsapp(target.value); setError('') }}
            />
          </div>

          <textarea
            rows={8}
            value={message}
            placeholder="Mensagem"
            onChange={({ target }) => { setMessage(target.value); setError('') }}
          />

          <button
            onClick={() => !loading && onSubmit()}
          >
            {loading ? 'Enviando...' : 'Enviar mensagem'}
          </button>

          {error ? <S.Error>{error}</S.Error> : null}
        </S.FormBox>
      </S.Container>
    </S.GetInTouch>
  );
};

export default GetInTouch;
