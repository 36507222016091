interface ILogoColpani {
	color?: string;
	symbolColor?: string;
}

const LogoColpani: React.FC<ILogoColpani> = ({ color, symbolColor }) => {
	return (
		<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			viewBox="0 0 327.76 91.01">
			<g>
				<path fill={symbolColor || '#38d430'} d="M45.5,0C20.41,0,0,20.41,0,45.5c0,25.09,20.41,45.5,45.5,45.5s45.5-20.41,45.5-45.5
		C91.01,20.41,70.59,0,45.5,0z M78.27,45.5l-8.19,8.19l-8.19-8.19l8.19-8.19L78.27,45.5z M54.48,34.95
		c-2.51-2.14-5.68-3.32-8.98-3.32c-7.65,0-13.87,6.22-13.87,13.87c0,7.65,6.22,13.87,13.87,13.87c3.3,0,6.47-1.17,8.98-3.32
		l8.23,8.22c-4.71,4.32-10.8,6.7-17.21,6.7c-14.05,0-25.48-11.43-25.48-25.48S31.46,20.03,45.5,20.03c6.41,0,12.49,2.37,17.21,6.7
		L54.48,34.95z"/>
				<g>
					<path fill={color || '#3d4543'} d="M185.61,14.36h8.68v32.4h15.27v8.1h-23.95V14.36z" />
					<path fill={color || '#3d4543'} d="M229.64,42.36h-6.13v12.5h-8.68v-40.5h14.81c7.75,0,13.88,6.36,13.88,14
			C243.52,36.17,237.39,42.36,229.64,42.36z M228.88,22.35h-5.38v11.98h5.38c3.76,0,5.78-2.49,5.78-5.96
			C234.67,25.07,232.12,22.35,228.88,22.35z"/>
					<path fill={color || '#3d4543'} d="M252.43,47.28l-3.24,7.58h-9.2l18.17-41.25l18.17,41.25h-9.2L264,47.28H252.43z M258.16,32.53l-2.78,7h5.67
			L258.16,32.53z"/>
					<path fill={color || '#3d4543'} d="M279.39,14.36h7.4l16.72,17.93V14.36h8.33v41.25l-24.12-26.96v26.21h-8.33V14.36z" />
					<path fill={color || '#3d4543'} d="M319.08,14.36h8.68v40.5h-8.68V14.36z" />
					<path fill={color || '#3d4543'} d="M159.45,13.81c-11.51,0-20.84,9.33-20.84,20.84s9.33,20.84,20.84,20.84c11.51,0,20.84-9.33,20.84-20.84
			S170.96,13.81,159.45,13.81z M159.45,46.92c-6.78,0-12.27-5.49-12.27-12.27s5.49-12.27,12.27-12.27s12.27,5.49,12.27,12.27
			S166.23,46.92,159.45,46.92z"/>
					<path fill={color || '#3d4543'} d="M132,42.61c-2.25,2.64-5.59,4.31-9.33,4.31c-6.78,0-12.27-5.49-12.27-12.27s5.49-12.27,12.27-12.27
			c3.74,0,7.08,1.68,9.33,4.31l6.07-6.07c-3.81-4.18-9.3-6.8-15.4-6.8c-11.51,0-20.83,9.33-20.83,20.84s9.33,20.84,20.83,20.84
			c6.1,0,11.59-2.62,15.4-6.8L132,42.61z"/>
				</g>
				<g>
					<path fill={color || '#3d4543'} d="M123.89,77.25h-6.14V66.52h6.14V68h-4.37v2.92h4.09v1.47h-4.09v3.37h4.37V77.25z" />
					<path fill={color || '#3d4543'} d="M134.97,77.25h-2.18l-5.34-8.55h-0.06l0.04,0.48c0.07,0.91,0.1,1.74,0.1,2.5v5.58h-1.61V66.52h2.15l5.32,8.51
			h0.04c-0.01-0.11-0.03-0.52-0.06-1.23c-0.03-0.71-0.04-1.26-0.04-1.66v-5.62h1.63V77.25z"/>
					<path fill={color || '#3d4543'} d="M141.97,71.43h3.99v5.39c-0.65,0.21-1.28,0.36-1.88,0.45c-0.6,0.09-1.25,0.13-1.95,0.13
			c-1.64,0-2.91-0.48-3.8-1.44c-0.89-0.96-1.34-2.32-1.34-4.07c0-1.73,0.5-3.08,1.51-4.06c1-0.98,2.39-1.46,4.15-1.46
			c1.13,0,2.21,0.22,3.22,0.65l-0.62,1.46c-0.88-0.4-1.76-0.6-2.64-0.6c-1.16,0-2.07,0.36-2.75,1.08s-1.01,1.7-1.01,2.95
			c0,1.31,0.3,2.31,0.91,2.99s1.48,1.02,2.62,1.02c0.57,0,1.19-0.07,1.84-0.21v-2.77h-2.25V71.43z"/>
					<path fill={color || '#3d4543'} d="M154.41,77.25h-6.14V66.52h6.14V68h-4.37v2.92h4.09v1.47h-4.09v3.37h4.37V77.25z" />
					<path fill={color || '#3d4543'} d="M165.5,77.25h-2.18l-5.34-8.55h-0.06l0.04,0.48c0.07,0.91,0.1,1.74,0.1,2.5v5.58h-1.61V66.52h2.15l5.32,8.51
			h0.04c-0.01-0.11-0.03-0.52-0.06-1.23c-0.03-0.71-0.04-1.26-0.04-1.66v-5.62h1.63V77.25z"/>
					<path fill={color || '#3d4543'} d="M176.62,77.25h-1.78v-4.84h-5v4.84h-1.77V66.52h1.77v4.39h5v-4.39h1.78V77.25z" />
					<path fill={color || '#3d4543'} d="M185.91,77.25l-1.08-2.98h-4.15l-1.06,2.98h-1.87l4.06-10.78h1.93l4.06,10.78H185.91z M184.36,72.76
			l-1.02-2.92c-0.07-0.2-0.18-0.5-0.31-0.92c-0.13-0.42-0.22-0.73-0.27-0.93c-0.13,0.6-0.33,1.26-0.59,1.97l-0.98,2.8H184.36z"/>
					<path fill={color || '#3d4543'} d="M190.7,72.96v4.29h-1.77V66.52h3.07c1.4,0,2.44,0.26,3.11,0.78c0.67,0.52,1.01,1.3,1.01,2.35
			c0,1.34-0.7,2.29-2.11,2.86l3.07,4.75h-2.02l-2.6-4.29H190.7z M190.7,71.51h1.23c0.83,0,1.43-0.15,1.8-0.46
			c0.37-0.3,0.56-0.75,0.56-1.35c0-0.61-0.2-1.04-0.6-1.31c-0.4-0.26-1.01-0.4-1.81-0.4h-1.17V71.51z"/>
					<path fill={color || '#3d4543'} d="M198.34,77.25V66.52h1.77v10.73H198.34z" />
					<path fill={color || '#3d4543'} d="M209.41,77.25l-1.08-2.98h-4.15l-1.06,2.98h-1.87l4.06-10.78h1.93l4.06,10.78H209.41z M207.86,72.76
			l-1.02-2.92c-0.07-0.2-0.18-0.5-0.31-0.92c-0.13-0.42-0.22-0.73-0.27-0.93c-0.13,0.6-0.33,1.26-0.59,1.97l-0.98,2.8H207.86z"/>
					<path fill={color || '#3d4543'} d="M224.9,71.78c0,1.77-0.5,3.13-1.49,4.06c-0.99,0.94-2.43,1.41-4.3,1.41h-3.04V66.52h3.36
			c1.73,0,3.07,0.46,4.03,1.38S224.9,70.11,224.9,71.78z M223.02,71.84c0-2.57-1.22-3.85-3.65-3.85h-1.54v7.79h1.26
			C221.72,75.77,223.02,74.46,223.02,71.84z"/>
					<path fill={color || '#3d4543'} d="M233.06,77.25h-6.14V66.52h6.14V68h-4.37v2.92h4.09v1.47h-4.09v3.37h4.37V77.25z" />
					<path fill={color || '#3d4543'} d="M240.53,72.96v4.29h-1.77V66.52h3.07c1.4,0,2.44,0.26,3.11,0.78c0.67,0.52,1.01,1.3,1.01,2.35
			c0,1.34-0.7,2.29-2.11,2.86l3.07,4.75h-2.02l-2.6-4.29H240.53z M240.53,71.51h1.23c0.83,0,1.43-0.15,1.8-0.46
			c0.37-0.3,0.56-0.75,0.56-1.35c0-0.61-0.2-1.04-0.6-1.31c-0.4-0.26-1.01-0.4-1.81-0.4h-1.17V71.51z"/>
					<path fill={color || '#3d4543'} d="M257.8,71.87c0,1.75-0.44,3.1-1.33,4.07c-0.88,0.97-2.13,1.45-3.75,1.45c-1.64,0-2.9-0.48-3.78-1.44
			s-1.32-2.33-1.32-4.1s0.44-3.13,1.33-4.08c0.88-0.95,2.14-1.42,3.78-1.42c1.61,0,2.86,0.48,3.74,1.45
			C257.36,68.76,257.8,70.12,257.8,71.87z M249.52,71.87c0,1.32,0.27,2.32,0.81,3.01c0.54,0.68,1.34,1.02,2.4,1.02
			c1.05,0,1.85-0.34,2.39-1.01c0.54-0.68,0.81-1.68,0.81-3.02c0-1.32-0.27-2.31-0.8-3c-0.53-0.68-1.33-1.02-2.38-1.02
			c-1.06,0-1.87,0.34-2.41,1.02C249.79,69.55,249.52,70.55,249.52,71.87z"/>
					<path fill={color || '#3d4543'} d="M268.66,71.78c0,1.77-0.5,3.13-1.49,4.06c-0.99,0.94-2.43,1.41-4.3,1.41h-3.04V66.52h3.36
			c1.73,0,3.07,0.46,4.03,1.38C268.18,68.82,268.66,70.11,268.66,71.78z M266.79,71.84c0-2.57-1.22-3.85-3.65-3.85h-1.54v7.79h1.26
			C265.48,75.77,266.79,74.46,266.79,71.84z"/>
					<path fill={color || '#3d4543'} d="M280.32,71.87c0,1.75-0.44,3.1-1.33,4.07c-0.88,0.97-2.13,1.45-3.75,1.45c-1.64,0-2.9-0.48-3.78-1.44
			c-0.88-0.96-1.32-2.33-1.32-4.1s0.44-3.13,1.33-4.08c0.88-0.95,2.14-1.42,3.78-1.42c1.61,0,2.86,0.48,3.74,1.45
			C279.88,68.76,280.32,70.12,280.32,71.87z M272.04,71.87c0,1.32,0.27,2.32,0.81,3.01c0.54,0.68,1.34,1.02,2.4,1.02
			c1.05,0,1.85-0.34,2.39-1.01c0.54-0.68,0.81-1.68,0.81-3.02c0-1.32-0.27-2.31-0.8-3c-0.53-0.68-1.33-1.02-2.38-1.02
			c-1.06,0-1.87,0.34-2.41,1.02C272.31,69.55,272.04,70.55,272.04,71.87z"/>
					<path fill={color || '#3d4543'} d="M288.53,66.52h1.84l-3.8,10.73h-1.87l-3.79-10.73h1.83l2.26,6.67c0.12,0.32,0.25,0.73,0.38,1.23
			c0.13,0.5,0.22,0.88,0.26,1.12c0.06-0.37,0.16-0.8,0.3-1.29c0.13-0.49,0.24-0.85,0.33-1.09L288.53,66.52z"/>
					<path fill={color || '#3d4543'} d="M291.51,77.25V66.52h1.77v10.73H291.51z" />
					<path fill={color || '#3d4543'} d="M302.58,77.25l-1.08-2.98h-4.15l-1.06,2.98h-1.87l4.06-10.78h1.93l4.06,10.78H302.58z M301.02,72.76
			l-1.02-2.92c-0.07-0.2-0.18-0.5-0.31-0.92c-0.13-0.42-0.22-0.73-0.27-0.93c-0.13,0.6-0.33,1.26-0.59,1.97l-0.98,2.8H301.02z"/>
					<path fill={color || '#3d4543'} d="M311.85,74.34c0,0.95-0.35,1.7-1.05,2.25c-0.7,0.54-1.66,0.82-2.89,0.82c-1.23,0-2.23-0.19-3.01-0.57v-1.66
			c0.49,0.23,1.02,0.41,1.58,0.54c0.56,0.13,1.07,0.2,1.56,0.2c0.7,0,1.22-0.13,1.55-0.4c0.33-0.26,0.5-0.62,0.5-1.06
			c0-0.4-0.15-0.74-0.46-1.02c-0.31-0.28-0.94-0.61-1.9-0.99c-0.99-0.4-1.69-0.85-2.09-1.36c-0.41-0.51-0.61-1.12-0.61-1.84
			c0-0.9,0.32-1.6,0.97-2.11c0.64-0.51,1.51-0.77,2.59-0.77c1.04,0,2.07,0.23,3.1,0.68l-0.56,1.43c-0.96-0.4-1.83-0.6-2.58-0.6
			c-0.57,0-1.01,0.12-1.31,0.37c-0.3,0.25-0.45,0.57-0.45,0.98c0,0.28,0.06,0.52,0.18,0.72c0.12,0.2,0.31,0.39,0.59,0.56
			c0.27,0.18,0.76,0.41,1.47,0.7c0.8,0.33,1.38,0.63,1.75,0.92s0.64,0.6,0.82,0.96C311.76,73.43,311.85,73.85,311.85,74.34z"/>
				</g>
			</g>
		</svg>
	);
};

export default LogoColpani;

