import C from '../Container';
import styled, { css } from 'styled-components';

export const GetInTouch = styled.section`
  width: 100%;
  padding: 120px 20px;
  background-size: cover;
  background-position: center;
  background-image: url('./bg-estradas.jpg');

  @media (max-width: 550px) {
    padding: 120px 0;
  }
`;

export const Container = styled(C)`
  display: flex;
  justify-content: flex-end;
`;

export const FormBox = styled.div<{ isLoading: boolean }>`
  ${({ isLoading }) => css`

    gap: 12px;
    width: 100%;
    display: flex;
    padding: 40px;
    max-width: 540px;
    border-radius: 40px;
    flex-direction: column;
    background-color: #35d32f;

    @media (max-width: 550px) {
      padding: 32px 20px;
      border-radius: 0;
    }

    div {
      gap: 12px;
      flex-grow: 1;
      display: flex;

      @media (max-width: 550px) {
        flex-direction: column;
      }
    }

    input, textarea {
      flex-grow: 1;
      border: none;
      outline: none;
      font-size: 16px;
      box-shadow: none;
      padding: 10px 14px;
      border-radius: 12px;
      background-color: white;
    }
    
    button {
      color: #fff;
      border: none;
      outline: none;
      font-size: 16px;
      font-weight: 600;
      box-shadow: none;
      margin-top: 12px;
      padding: 16px 32px;
      border-radius: 16px;
      align-self: flex-start;
      background-color: #2bb526;
      cursor: ${isLoading ? 'not-allowed' : 'pointer'};
      animation: ${isLoading ? 'pulse 1.2s infinite' : 'none'};
    }

    @keyframes pulse {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }
  `}
`;

export const Title = styled.h4`
  color: #3c4543;
  font-size: 22px;
  font-weight: 800;
  margin-bottom: 16px;
`;

export const Error = styled.small`
  color: white;
  padding: 8px;
  font-size: 14px;
  border-radius: 8px;
  background-color: red;
`;
