import { useCallback, useEffect, useState } from 'react';
import * as S from './styles';

const clients = [
  'logo-a-becker.png',
  'logo-abc.png',
  'logo-al.png',
  'logo-amp.png',
  'logo-avantis.png',
  'logo-balneario-camboriu.png',
  'logo-barigui.png',
  'logo-barra-velha.png',
  'logo-britania.png',
  'logo-buffon.png',
  'logo-cadence.png',
  'logo-conlog.png',
  'logo-dicave.png',
  'logo-distle.png',
  'logo-elio-winter.png',
  'logo-expresso-sao-miguel.png',
  'logo-fardo.png',
  'logo-flamingo-casa.png',
  'logo-grupo-barigui.png',
  'logo-grupo-pegoraro.png',
  'logo-grupo-sinuelo.png',
  'logo-heineken.png',
  'logo-hoteis-101.png',
  'logo-innovar.png',
  'logo-itapema.png',
  'logo-jamef.png',
  'logo-john-deere.png',
  'logo-jta.png',
  'logo-juninho.png',
  'logo-maiochi-tigrao.png',
  'logo-milium.png',
  'logo-mime.png',
  'logo-palhoca.png',
  'logo-pasqualoto.png',
  'logo-pelanda.png',
  'logo-pessoa.png',
  'logo-picarras.png',
  'logo-plasticos-perin.png',
  'logo-porto-belo.png',
  'logo-portobello-outlet-premium.png',
  'logo-posto-delta.png',
  'logo-posto-rudnick.png',
  'logo-postos-modesto.png',
  'logo-sinuelo.png',
  'logo-taschibra.png',
  'logo-trans-ferrari.png',
  'logo-tulio.png',
  'logo-valentim.png',
  'logo-versatille.png',
  'logo-vokkan.png',
  'logo-wert.png',
  'logo-zandona.png',
  'logo-ap.png',
  'logo-artely.png',
  'logo-graal.png',
  'logo-harley.png',
  'logo-havan.png',
  'logo-jmalucelli.png',
  'logo-paraiso.png',
  'logo-picarras-business.png',
  'logo-portobello.png',
].sort();

interface IClients { }

const Clients: React.FC<IClients> = () => {
  const [selectedSlide, setSelectedSlide] = useState(0);

  const onNextSlide = useCallback(() => {
    if (selectedSlide < clients.length - 3)
      setSelectedSlide(c => c + 1);
    else setSelectedSlide(0);
  }, [selectedSlide])

  const onBackSlide = () => {
    if (selectedSlide > 0)
      setSelectedSlide(c => c - 1);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      onNextSlide();
    }, 3000);

    return () => clearInterval(interval);
  }, [onNextSlide]);

  return (
    <S.Clients id="clients">
      <S.Container>
        <S.TitleDescription>
          <S.Title>Clientes</S.Title>

          <S.Description>
            Empresas que confiam
            <span> no trabalho da Colpani</span>:
          </S.Description>
        </S.TitleDescription>

        <S.Slider>
          <S.SliderContainer offset={selectedSlide}>
            {[...clients, ' '].map((client, idx) => (
              <S.Slide key={idx} img={client} />
            ))}
          </S.SliderContainer>

          <S.Controls>
            <S.Left
              onClick={onBackSlide}
              isLast={selectedSlide === 0}
            >
              <span /><span />
            </S.Left>

            <S.Right
              onClick={onNextSlide}
              isLast={selectedSlide === clients.length - 3}
            >
              <span /><span />
            </S.Right>
          </S.Controls>
        </S.Slider>
      </S.Container>
    </S.Clients>
  );
};

export default Clients;
