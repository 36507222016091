interface IIcon {
  color?: string;
}

const WhatsappIcon: React.FC<IIcon> = ({ color }) => {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.104903 12.7372L2.9908 11.7119L3.23129 11.8498C4.24244 12.4299 5.40153 12.7368 6.5833 12.7372H6.58597C10.2159 12.7372 13.1702 9.88126 13.1717 6.37091C13.1724 4.66982 12.488 3.07034 11.2446 1.86698C10.0012 0.663621 8.34763 0.000589568 6.58856 0C2.95585 0 0.0014425 2.85567 5.58863e-07 6.36575C-0.00050917 7.56869 0.347676 8.7402 1.00693 9.75379L1.16351 9.99466L0.104903 12.7372ZM10.3067 8.02042C10.4448 8.08493 10.538 8.12849 10.5778 8.19274C10.6273 8.27254 10.6273 8.65573 10.4624 9.10286C10.2974 9.5499 9.50641 9.95791 9.126 10.0128C8.78492 10.0621 8.35327 10.0827 7.879 9.937C7.59144 9.84881 7.22269 9.73105 6.75033 9.53385C4.89426 8.75912 3.63994 7.02016 3.40288 6.6915C3.38627 6.66848 3.37466 6.65237 3.36818 6.64401L3.36658 6.64195C3.2618 6.50681 2.55975 5.60133 2.55975 4.6642C2.55975 3.78263 3.00773 3.32056 3.21393 3.10786C3.22806 3.09329 3.24105 3.07989 3.25269 3.0676C3.43416 2.876 3.64866 2.82809 3.78064 2.82809C3.91261 2.82809 4.04475 2.82927 4.1601 2.83487C4.17433 2.83556 4.18914 2.83548 4.20444 2.83539C4.31982 2.83474 4.46366 2.83393 4.60555 3.16341C4.66014 3.29021 4.74002 3.47819 4.82427 3.67645C4.99463 4.07738 5.18286 4.52036 5.21598 4.58446C5.26548 4.68026 5.29847 4.79199 5.23248 4.91977C5.22258 4.93893 5.21341 4.95701 5.20466 4.97429C5.15509 5.07211 5.11863 5.14406 5.0345 5.23902C5.00142 5.27636 4.96723 5.31661 4.93304 5.35685C4.86493 5.43704 4.79682 5.51722 4.73752 5.57433C4.6384 5.66976 4.53523 5.7733 4.65072 5.96491C4.76621 6.15652 5.16355 6.78322 5.75212 7.29072C6.38481 7.83624 6.93469 8.0668 7.21342 8.18368C7.26785 8.2065 7.31194 8.22499 7.34429 8.24063C7.54219 8.33647 7.65769 8.32043 7.77319 8.19274C7.88869 8.06501 8.26811 7.63388 8.40008 7.44231C8.53204 7.25077 8.66408 7.28268 8.84552 7.34652C9.02704 7.41045 10.0004 7.87339 10.1984 7.96918C10.2371 7.98789 10.2732 8.00477 10.3067 8.02042Z" fill={color || "black"} />
    </svg>
  )
};

export default WhatsappIcon;
