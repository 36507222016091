import * as S from './styles';
import Container from '../Container';

interface IHero { }

const Hero: React.FC<IHero> = () => {
  return (
    <S.Hero>
      <Container>
        <S.Content>
          <S.Title>
            Antes de construir 
            <br />
            <S.Highlight>às margens da rodovia</S.Highlight>
            <br />
            consulte a
            <br />
            <S.Highlight>Colpani Engenharia</S.Highlight>
          </S.Title>

          <S.CallToAction
            target='_blank'
            href='https://wa.me/5547999979055?text=Olá, Colpani'
          >
            Fale conosco
          </S.CallToAction>
        </S.Content>
      </Container>

      <S.Image>
        <S.Partners />
      </S.Image>
    </S.Hero>
  );
};

export default Hero;
