import styled from "styled-components";

export const Hero = styled.section`
  display: flex;
  height: 640px;
  margin-top: 140px;
  position: relative;
  align-items: center;

  @media (max-width: 920px) {
    flex-direction: column;
  }

  @media (max-width: 500px) {
    margin-top: 120px;
    height: 480px;
  }
`;

export const Content = styled.div`
  gap: 20px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 920px) {
    align-items: center;
    padding-bottom: 80px;
  }

  @media (max-width: 500px) {
    padding-bottom: 40px;
  }
`;

export const Title = styled.h2`
  color: #3c4543;
  font-size: 32px;
  font-weight: 500;
  line-height: 130%;

  @media (max-width: 920px) {
    gap: 8px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 640px;
    align-items: center;
    white-space: nowrap;
    justify-content: center;

    br {
      display: none;
    }
  }

  @media (max-width: 620px) {
    font-size: 24px;
    max-width: 400px;
    line-height: 100%;
  }
`;

export const Highlight = styled.span`
  color: #35d32f;
  font-size: 32px;
  font-weight: 800;
  font-style: italic;

  @media (max-width: 620px) {
    font-size: 24px;
  }
`;

export const CallToAction = styled.a`
  border: none;
  color: #3c4543;
  cursor: pointer;
  font-size: 14px;
  background: none;
  box-shadow: none;
  font-weight: 700;
  padding: 12px 24px;
  border-radius: 20px;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #35d32f;
`;

export const Image = styled.div`
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  position: absolute;
  background-size: cover;
  border-radius: 140px 0 0 0;
  background-position: center left;
  background-image: url("./hero-bg.jpg");

  @media (max-width: 920px) {
    width: 100%;
    border-radius: 0;
    position: relative;
  }
`;

export const Partners = styled.div`
  bottom: 0;
  left: -15%;
  z-index: 1;
  height: 90%;
  width: 480px;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-image: url("./hero-partners.png");

  @media (max-width: 920px) {
    left: 50%;
    width: 50%;
    height: 120%;
  }
  @media (max-width: 630px) {
    left: 50%;
    width: 80%;
    height: 110%;
    transform: translateX(-50%);
  }
`;
