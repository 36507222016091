import * as S from './styles'

interface IContainer {
  className?: string;
  children: React.ReactNode;
}

const Container: React.FC<IContainer> = ({ children, className }) => {
  return (
    <S.Container className={className}>
      {children}
    </S.Container>
  )
};

export default Container;
