import C from "../Container";
import styled from "styled-components";

export const Footer = styled.footer`
  background-color: #3c4543;
`;

export const Container = styled(C)`
  gap: 60px;
  display: flex;
  padding: 80px 20px;
  flex-direction: column;
`;

export const MainContent = styled.div`
  gap: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 720px) {
    flex-wrap: wrap;
    padding-left: 34px;
  }
`;

export const Copyright = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 160px;
  }

  @media (max-width: 440px) {
    gap: 24px;
    flex-direction: column-reverse;
  }
`;

export const CopywrigthText = styled.small`
  color: #fff;
  font-size: 12px;

  span {
    color: #35d32f;
  }
`;

export const SocialMedia = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  max-width: 200px;
  flex-direction: column;

  @media (max-width: 720px) {
    order: 2;
    -ms-flex-order: 2;
  }
`;

export const SocialMediaList = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
`;

export const SocialMediaItem = styled.a`
  width: 20px;
  height: 20px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Description = styled.p`
  color: #fff;
  font-size: 18px;

  span {
    font-weight: 700;
  }
`;

export const Address = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;

  div {
    gap: 8px;
    display: flex;
    margin-left: -34px;
    align-items: center;

    svg {
      width: 26px;
      height: 26px;
    }
  }
`;

export const AddressContent = styled.p`
  color: #fff;
  width: 100%;
  font-size: 16px;
  max-width: 340px;
  line-height: 140%;

  @media (max-width: 400px) {
    order: 1;
    max-width: 100%;
    -ms-flex-order: 1;
  }
`;

export const GetInTouch = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 180px;

  div {
    gap: 12px;
    display: flex;
    margin-left: -32px;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 720px) {
    order: 1;
    -ms-flex-order: 1;
  }
`;

export const PhoneLink = styled.a`
  color: white;
  font-size: 22px;
  font-weight: 800;
  line-height: 100%;
  text-transform: none;
  text-decoration: none;

  span {
    font-size: 18px;
  }
`;
