interface IIcon {
  color?: string;
}

const LinkedinIcon: React.FC<IIcon> = ({ color }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.98361 14H0.229508V4.2507H2.98361V14ZM1.60656 3.12687C0.719738 3.12687 0 2.42669 0 1.56343C0 0.700177 0.719738 0 1.60656 0C2.49338 0 3.21311 0.700177 3.21311 1.56343C3.21311 2.42669 2.4943 3.12687 1.60656 3.12687ZM14 14H11.2459V9.03317C11.2459 6.04811 7.57377 6.27412 7.57377 9.03317V14H4.81967V4.2507H7.57377V5.81502C8.85534 3.52304 14 3.35376 14 8.0095V14Z" fill={color || "black"} />
    </svg>
  )
};

export default LinkedinIcon;